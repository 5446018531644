import * as React from 'react';
import { useState, useCallback  } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Form, Input, Modal, ModalHeader, ModalBody, ModalFooter, Label, Row, Spinner,Button } from 'reactstrap';
import { ApplicationState } from '../../store';

import { IFileDownloadInfo, IOrder, orderServiceFactory } from '../../services/orderService';
import './Orders.css';
import Context from '../../configuration/context';
import userServiceFactory, { IAEHierarchyItem, IHierarchyItem } from '../../services/userService'
import { useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import * as RRD from 'react-router-dom'
import * as AuthenticationStore from '../../store/Authentication'
import { IOption, ControlledNumberInput, ControlledTextInput, ControlledDatePickerInput, ControlledFileUploadInput, ControlledRadioButtons, ControlledSelect, ControlledTextArea, ControlledCheckboxInput } from '../controls/FormComponents';
import { useForm } from 'react-hook-form';
import { IAuthenticationActionCreators, UserState } from '../../store/Authentication';
import { authenticationServiceFactory } from '../../services/authenticationService';
import dayjs from 'dayjs';
import sanitizeHtml from 'sanitize-html';
import { appInsights } from '../../services/applicationInsights';

export type IOrderProps =
IAuthenticationActionCreators &
{
    authenticationState?: UserState;
}

const LoadState = {
    IDLE : "IDLE",
    LOADING : "LOADING",
    LOADED :  "LOADED",
    ERROR : "ERROR"
}

const SubmissionState = {
    IDLE: "IDLE",
    SUBMITTING: "SUBMITTING",
    SUBMITTED: "SUBMITTED",
    ERROR: "ERROR"
}
export interface IOrderModel {
    orderDate?: Date;
    orderNumber?: string;
    orderStatus: number;
    orderType: number;
    createdBy: string;
    createdByPhone: string;
    createdByEmail: string;
    externalReference: string;
    costBearingUnit?: string;
    requestedService: string;
    requestedServiceDescription: string;
    otherInformation: string;
    requestedDeliveryDateSpecification: "asap" | "specificDate";
    requestedDeliveryDate: Date;
    scheduledDate: Date;
    bookingDetails: string;
    digitalDeliveryIsPreferred: string;
    feedbackMethod: number;
    specificFeedbackRecipient: string;
    specificFeedbackRecipientDetails: string;
    preferredPerformingUnit?: string; 
    attachments: File[];
    employeeName: string;
    employeeAddress: string;
    employeeMobilePhone: string;
    employeeWorkPhone: string;
    employeeEmail: string;
    employeePersonalIdentityNumber: string;
    groupSize: string;
    additionalPerformingUnits: string[];    
    smsConsentCollected: boolean;
}

function ToOrderModel(order: IOrder) : IOrderModel {
    return {
        additionalPerformingUnits : order.additionalPerformingUnits.map(u => u.id),
        attachments :[],
        createdBy : order.createdBy,
        createdByEmail : order.createdByEmail,
        createdByPhone : order.createdByPhone,
        costBearingUnit : order.costBearingUnit.id,
        digitalDeliveryIsPreferred: order.digitalDeliveryIsPreferred ? "yes" : "no",
        employeeAddress : order.employeeAddress,
        employeeEmail : order.employeeEmail,
        employeeMobilePhone : order.employeeMobilePhone,
        employeeName : order.employeeName,
        employeePersonalIdentityNumber : order.employeePersonalIdentityNumber,
        employeeWorkPhone : order.employeeWorkPhone,
        externalReference : order.externalReference,
        feedbackMethod : order.feedbackMethod,
        groupSize : order.groupSize,
        otherInformation : order.otherInformation,
        preferredPerformingUnit : order.preferredPerformingUnit.id,
        requestedDeliveryDate : order.requestedDeliveryDate,
        requestedService : order.requestedService,
        requestedServiceDescription : order.requestedServiceDescription,
        specificFeedbackRecipient : order.specificFeedbackRecipient ? "yes" : "no",
        specificFeedbackRecipientDetails:order.specificFeedbackRecipientDetails,
        orderDate : order.created,
        orderNumber : order.orderId,
        orderType : order.orderType,
        scheduledDate : order.scheduledDate,
        bookingDetails: order.bookingDetails,
        orderStatus : order.orderStatus,
        requestedDeliveryDateSpecification : order.requestedDeliveryDate ? "specificDate" : "asap",
        smsConsentCollected: order.smsConsentCollected
    } as IOrderModel;
}

interface IParams {
    orderNumber?:string;
}

const Order = function ({ authenticationState, setLoginState }: IOrderProps) {
    const { t, ready } = useTranslation('orders');

    
    const location = RRD.useLocation();
    const navigate = RRD.useNavigate();

    const { orderNumber } = RRD.useParams();
    const isCopyOrder: boolean = location.pathname.indexOf("/copy") >= 0;
    
    const formIsReadOnly: boolean = !!orderNumber && !isCopyOrder;
    

    const [aEHierarchyOptions, setAEHierarchyOptions] = useState<IOption<string>[]>([]);
    const [aeHierarchyLoadState, setAeHierarchyLoadState] = useState<string>(LoadState.IDLE);    
    const [hierarchyOptions, setHierarchyOptions] = useState<IOption<string>[]>([]);
    const [hierarchyLoadState, setHierarchyLoadState] = useState<string>(LoadState.IDLE);
    const [showResultModal, setShowResultModal] = useState<boolean>(false);
    const [submissionState, setSubmissionState] = useState<string>(SubmissionState.IDLE);
    const [orderLoadState, setOrderLoadState] = useState<string>(LoadState.IDLE);
    const [readOnlyOrder, setOrder] = useState<IOrder>();

    const loginState = authenticationState?.LoginState;
    const currentUser = authenticationState?.UserInRole;

    var defaultOrderModel:any = {
        createdBy:  currentUser ? currentUser?.name : "",
        createdByEmail: (currentUser && currentUser?.email) ? currentUser?.email : "",
        groupSize: "",
        externalReference: "",
        createdByPhone: "",
        preferredPerformingUnit: "",
        requestedDeliveryDate:null,
        digitalDeliveryIsPreferred: "yes",
        smsConsentCollected: false,
        //requestedDeliveryDate: new Date(Date.parse(Date().toString()) + (3600 * 7000 * 24)),
        requestedDeliveryDateSpecification : "asap"
    };

    

    const [feedbackOptions, setFeedbackOptions] = useState<IOption<number>[]>([]);

    const transformCustomersToCostBearerOptionsList = useCallback((items: IHierarchyItem[]) => {
        const Flatten = (currentItem: IHierarchyItem, destination: IOption<string>[], level?: number) => {
            const _level: number = level ? level : 0;
            destination.push({
                label: currentItem.name,
                value: currentItem.id,
                recursionLevel : _level
            });
            var items = currentItem.children
                .sort((a, b) => { return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : (b.name.toLowerCase() > a.name.toLowerCase() ? 1 : 0) });
            items.map(c => Flatten(c, destination, _level + 1));
        }
        const o = [] as IOption<string>[];
        items.map(i => Flatten(i, o));
        return o;
    }, []);

    const urlSegments = document.location.href.split("/");
    let isIndividualOrder: boolean = urlSegments[5] === "individual";
    let isGroupOrder: boolean = urlSegments[5] === "group";
    let isEducationOrder: boolean = urlSegments[5] === "education";
    const { reset, watch, handleSubmit, formState: { errors  }, control, setValue } = useForm<IOrderModel>({ defaultValues: defaultOrderModel, mode: "all" });

    const downloadAttachment = (url:string, filename:string) => {
        return orderServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).getAttachmentContent(url).then((text: Blob) => {
            let file = new File([text], filename, { type: "application/octet-stream", });
            let exportUrl = URL.createObjectURL(file);
            const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
            a.href = exportUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(exportUrl);
        });
    }

    let order: IOrderModel = watch();

    // Load order
    useEffect(() => {
        // Anything in here is fired on component mount.
        if (loginState && loginState === AuthenticationStore.LoginState.LOGGED_IN 
            && orderNumber 
            && aeHierarchyLoadState === LoadState.LOADED 
            && hierarchyLoadState === LoadState.LOADED) {
            
            setOrderLoadState(LoadState.LOADING);
            orderServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState)).getOrder(orderNumber)
                .then((_order: IOrder) => {
                    const orderModel = ToOrderModel(_order);
                    if (isCopyOrder){
                        orderModel.createdBy = currentUser ? currentUser?.name : "";
                        orderModel.createdByEmail = (currentUser && currentUser?.email) ? currentUser?.email : "";
                        orderModel.attachments = [];
                        orderModel.requestedDeliveryDate = new Date(Date.parse(Date().toString()) + (3600 * 7000 * 24));
                        orderModel.bookingDetails = "";
                        orderModel.orderStatus = 0;
                        orderModel.smsConsentCollected = false;
                    }
                    reset(orderModel)
                    setOrderLoadState(LoadState.LOADED);
                    setOrder(_order);
                }).catch((error: any) => {
                    setOrderLoadState(LoadState.ERROR);
                    // setOrderModel(null);
                });
        }

    }, [loginState, orderNumber, aeHierarchyLoadState, hierarchyLoadState, reset, currentUser, isCopyOrder, authenticationState, setLoginState]);


    useEffect(() => {
        // Anything in here is fired on component mount.
        if (ready) {
            setFeedbackOptions([
                { label: t('OPTION_FEEDBACK_METHOD_YES'), value: 3 },
                { label: t('OPTION_FEEDBACK_METHOD_YES_ORALLY'), value: 1, isDeprecated: true },
                { label: t('OPTION_FEEDBACK_METHOD_YES_IN_WRITING'), value: 2, isDeprecated : true },
                { label: t('OPTION_FEEDBACK_METHOD_NO'), value: 0 }]);
        }
    }, [ready, setValue,t,order.feedbackMethod]);

    useEffect(() => {
        // Anything in here is fired on component mount.
        if (loginState && loginState === AuthenticationStore.LoginState.LOGGED_IN  && currentUser && currentUser.email && currentUser.selectedCustomer) {
            var service = userServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState));
            
            setAeHierarchyLoadState(LoadState.LOADING);
            service.GetAEHierarchy(currentUser?.selectedCustomer?.customerId.toString())
                .then((hierarchy: IAEHierarchyItem[]) => {
                    setAeHierarchyLoadState(LoadState.LOADED);
                    setAEHierarchyOptions(hierarchy.map(i => {return { value : i.id, label : i.name}}));
                    var defaultPerformingUnit = hierarchy.filter(f => f.default);
                    reset({...order, preferredPerformingUnit : (defaultPerformingUnit.length == 1 ? defaultPerformingUnit[0].id : order.preferredPerformingUnit )})
                }).catch((error: any) => {
                    setAeHierarchyLoadState(LoadState.ERROR);
                    setAEHierarchyOptions([]);
                });

            setAeHierarchyLoadState(LoadState.LOADING);
            service.GetRelatedCustomerHierarchy(currentUser?.selectedCustomer?.customerId.toString())
                .then((hierarchy: IHierarchyItem[]) => {
                    let options = transformCustomersToCostBearerOptionsList(hierarchy);
                    setHierarchyOptions(options);
                   setHierarchyLoadState(LoadState.LOADED)
                }).catch((error: any) => {
                    setHierarchyOptions([]);
                    setHierarchyLoadState(LoadState.ERROR)
                });
        }
    }, [loginState, currentUser, setValue, transformCustomersToCostBearerOptionsList,authenticationState, setLoginState ]);

    const submitButtonText: string = t('ORDER_SUBMIT');
    
    const removeAttachment = (index:number) => {
        setValue('attachments', order.attachments.filter((i,_index) => { return index !== _index } ));
    };

    const onSubmit = (order:IOrderModel) => {

        if(order.preferredPerformingUnit === "-1"){
            setShowResultModal(true);
            setSubmissionState(SubmissionState.SUBMITTED);
            return;
        }

        var service = orderServiceFactory(Context.APIUrl + "/api/v1", authenticationServiceFactory(Context.APIUrl, { setLoginState }, authenticationState));
        
        setShowResultModal(true);
        setSubmissionState(SubmissionState.SUBMITTING);

        service.saveOrder({
            createdBy : order.createdBy,
            createdByEmail : order.createdByEmail,
            createdByPhone: order.createdByPhone,
            externalReference : order.externalReference,
            costBearingUnit: order.costBearingUnit ? order.costBearingUnit : "",
            groupSize: order.groupSize ,
            requestedServiceDescription:order.requestedServiceDescription,
            otherInformation : order.otherInformation,
            requestedDeliveryDate : order.requestedDeliveryDate,
            digitalDeliveryIsPreferred : order.digitalDeliveryIsPreferred === "yes",
            feedbackMethod: order.feedbackMethod.toString(),
            specificFeedbackRecipient: order.feedbackMethod !== 0 && order.specificFeedbackRecipient === "yes",
            specificFeedbackRecipientDetails: (order.feedbackMethod !== 0 && order.specificFeedbackRecipient === "yes")
                ? order.specificFeedbackRecipientDetails 
                : "",
            preferredPerformingUnit: order.preferredPerformingUnit ? order.preferredPerformingUnit : "",
            additionalPerformingUnits: order.additionalPerformingUnits ? order.additionalPerformingUnits.map(u => u) : [],
            employeeName : order.employeeName,
            employeeAddress : order.employeeAddress,
            employeeMobilePhone: order.employeeMobilePhone,
            employeeWorkPhone: order.employeeWorkPhone,
            employeeEmail : order.employeeEmail,
            employeePersonalIdentityNumber : order.employeePersonalIdentityNumber,
            requestedService: "Custom service",
            customerId: currentUser && currentUser.selectedCustomer ? currentUser?.selectedCustomer?.customerId.toString() : "",
            orderType: urlSegments[5],
            smsConsentCollected: order.smsConsentCollected         
        }, order.attachments).then((order:IOrder) => {
            setSubmissionState(SubmissionState.SUBMITTED);
            appInsights.trackEvent({ name: "Order submission succeeded" }, { customer: currentUser?.selectedCustomer?.customerId });
        }, (error:any) => {
            appInsights.trackEvent({ name: "Order submission failed" }, { customer: currentUser?.selectedCustomer?.customerId, error: JSON.stringify(error) });
            setSubmissionState(SubmissionState.ERROR);
        });
    }

    console.log(order);

    return (
        <div className="order-form limited">
            {   ((orderNumber && orderLoadState === LoadState.LOADING) ||
                    aeHierarchyLoadState === LoadState.LOADING || 
                    hierarchyLoadState === LoadState.LOADING 

                ) && 
                <h1 className="loading-indicator-container col-12"><Spinner /> {t('FETCHING_ORDER')}...</h1>
            }
            {(aeHierarchyLoadState === LoadState.ERROR || hierarchyLoadState === LoadState.ERROR || orderLoadState === LoadState.ERROR) && 
                <h1 className="loading-indicator-container col-12">Error! Please reload page! If it doesnt help, try logging out and logging in again.</h1>
            }
            {(aeHierarchyLoadState === LoadState.LOADED && hierarchyLoadState === LoadState.LOADED && (!orderNumber || orderLoadState === LoadState.LOADED)) &&
            <>
                {isIndividualOrder && <h1>{t('TYPE_Individual_FULL')}</h1>}
                {isGroupOrder && <h1>{t('TYPE_Group_FULL')}</h1>}
                {isEducationOrder && <h1>{t('TYPE_Education_FULL')}</h1>}   
                <Form key={order.orderNumber} onSubmit={handleSubmit((order, event) => {
                    event && event.preventDefault();
                    event && event.stopPropagation(); 
                    onSubmit(order) }, (e) => {console.error(e)})}>
                    <fieldset>
                    {formIsReadOnly &&
                    
                        <Row>
                            <Label className="col-md-6 mt-4">{t('ORDER_ID')}
                                <ControlledTextInput<IOrderModel>
                                    control={control}
                                    fieldName="orderNumber"
                                    readonly={true} />
                            </Label>
                            <Label className="col-md-6 mt-4">{t('ORDER_DATE')}
                                <ControlledDatePickerInput<IOrderModel>
                                    control={control}
                                    fieldName="orderDate"
                                    readonly={true}
                                    placeholder={t('ORDER_DATE')}
                                />                               
                            </Label>
                        </Row>
                    }
                    
                    {!formIsReadOnly && <Row>
                        <Col className="col-12">
                            <strong>{t('MANDATORY_FIELDS_INSTRUCTIONS')}</strong>
                        </Col>
                    </Row>
                    }
                   
                    <Row>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_CREATED_BY')}*
                            <ControlledTextInput<IOrderModel>
                                rules={{ required: true }}
                                control={control}
                                fieldName="createdBy"
                                readonly={formIsReadOnly}
                                placeholder={t('FIELD_PLACEHOLDER_CREATED_BY')} />
                        </Label>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_ORDER_PHONE')}*
                            <ControlledTextInput<IOrderModel>
                                rules={{ required: true }}
                                control={control}
                                fieldName="createdByPhone"
                                readonly={formIsReadOnly}
                                placeholder={t('FIELD_PLACEHOLDER_ORDER_PHONE')} />
                            <span className="field-description">{t('FIELD_DESCRIPTION_ORDER_PHONE')}</span>
                        </Label>
                    </Row>
                    <Row>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_ORDER_EMAIL')}*
                            <ControlledTextInput<IOrderModel>
                                rules={{ required: true }}
                                control={control}
                                fieldName="createdByEmail"
                                readonly={formIsReadOnly}
                                placeholder={t('FIELD_PLACEHOLDER_ORDER_EMAIL')} />
                        </Label>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_EXTERNAL_REFERENCE')}
                            <ControlledTextInput<IOrderModel>                           
                                control={control}
                                fieldName="externalReference"
                                readonly={formIsReadOnly}
                                placeholder={t('FIELD_PLACEHOLDER_EXTERNAL_REFERENCE')}
                            />
                        </Label>
                    </Row>
                    <Row>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_COST_BEARING_UNIT')}*
                            <ControlledSelect<IOrderModel, string>
                                getOptionLabel={(item: IOption<string>) => (item.recursionLevel ? "\u00A0".repeat(item.recursionLevel * 3) : "") + item.label}
                                getOptionValue={(item: IOption<string>) => item.value}
                                options={hierarchyOptions}
                                rules={{ required: true }}
                                control={control}
                                fieldName="costBearingUnit"
                                readonly={formIsReadOnly}
                                placeholder={t('FIELD_PLACEHOLDER_COST_BEARING_UNIT')}
                            />
                        </Label>
                    </Row>
                        <hr className="mt-4"/>
                {(isIndividualOrder || (orderLoadState === LoadState.LOADED && order.orderType === 0)) &&
                        <>
                            <Row>
                                <Label className="col-md-6 mt-4">{t('FIELDLABEL_EMPLOYEE_NAME')}*
                                    <ControlledTextInput<IOrderModel>
                                            rules={{ required: true }}
                                            control={control}
                                            fieldName="employeeName"
                                            readonly={formIsReadOnly}
                                            placeholder={t('FIELD_PLACEHOLDER_EMPLOYEE_NAME')} />
                                    <span className="field-description">{t('FIELD_DESCRIPTION_EMPLOYEE_NAME')}</span>
                                </Label>
                                <Label className="col-md-6 mt-4">{t('FIELDLABEL_EMPLOYEE_PERSONAL_IDENTITY_NUMBER')}*
                                    <ControlledTextInput<IOrderModel>
                                        rules={{ required: true }}
                                        control={control}
                                        fieldName="employeePersonalIdentityNumber"
                                        readonly={formIsReadOnly}
                                        placeholder={t('FIELD_PLACEHOLDER_EMPLOYEE_PERSONAL_IDENTITY_NUMBER')} />
                                    <span className="field-description">{t('FIELD_DESCRIPTION_EMPLOYEE_PERSONAL_IDENTITY_NUMBER')}</span>
                                </Label>

                                <Label className="col-md-6 mt-4">{t('FIELDLABEL_EMPLOYEE_MOBILE_PHONE')}*
                                    <ControlledTextInput<IOrderModel>
                                            rules={{ required: true }}
                                            control={control}
                                            fieldName="employeeMobilePhone"
                                            readonly={formIsReadOnly}
                                            placeholder={t('FIELD_PLACEHOLDER_EMPLOYEE_MOBILE_PHONE')} />
                                    <span className="field-description">{t('FIELD_DESCRIPTION_EMPLOYEE_MOBILE_PHONE')}</span>
                                    
                                    </Label>
                                {/* <Label className="col-md-6 mt-4">{t('FIELDLABEL_EMPLOYEE_WORK_PHONE')}
                                    <ControlledTextInput<IOrderModel>
                                        rules={{}}
                                            control={control}
                                            fieldName="employeeWorkPhone"
                                            readonly={formIsReadOnly}
                                            placeholder={t('FIELD_PLACEHOLDER_EMPLOYEE_WORK_PHONE')} />
                                    <span className="field-description">{t('FIELD_DESCRIPTION_EMPLOYEE_WORK_PHONE')}</span>
                                    </Label> */}

                                <Label className="col-md-6 mt-4">{t('FIELDLABEL_EMPLOYEE_EMAIL')}
                                    <ControlledTextInput<IOrderModel>
                                            rules={{}}
                                            control={control}
                                            fieldName="employeeEmail"
                                            readonly={formIsReadOnly}
                                            placeholder={t('FIELD_PLACEHOLDER_EMPLOYEE_EMAIL')} />
                                </Label>
                                <Label className="col-md-6 mt-4">{t('FIELDLABEL_EMPLOYEE_ADDRESS')}
                                    <ControlledTextArea<IOrderModel>
                                        rules={{}}
                                        control={control}
                                        fieldName="employeeAddress"
                                        readonly={formIsReadOnly}
                                        placeholder={t('FIELD_PLACEHOLDER_EMPLOYEE_ADDRESS')} />
                                </Label>
                                <Label className="col-md-6 mt-4">
                                    <ControlledCheckboxInput<IOrderModel>
                                        rules={{ required: true }}
                                        control={control}
                                        readonly={formIsReadOnly}
                                        fieldName="smsConsentCollected"                                        
                                    />{t('FIELDLABEL_SMS_CONSENT')}*
                                    <div className="field-description">{t('FIELD_DESCRIPTION_SMS_CONSENT')}</div>
                                </Label>
                            </Row>
                        </>
                    }
                {((isGroupOrder || (orderLoadState === LoadState.LOADED && order.orderType === 2)) || (isEducationOrder || (orderLoadState === LoadState.LOADED && order.orderType === 1))) &&
                        <>
                        <Row>
                            <Label className="col-md-6 mt-4">{t('FIELDLABEL_GROUP_SIZE')}*
                                <ControlledNumberInput<IOrderModel>
                                    rules={{required : true}}
                                    control={control}
                                    fieldName="groupSize"
                                    readonly={formIsReadOnly}
                                    placeholder={t('FIELD_PLACEHOLDER_GROUP_SIZE')} />
                                <span className="field-description">{t('FIELD_DESCRIPTION_GROUP_SIZE')}</span>
                            </Label>
                            {(isGroupOrder || (orderLoadState === LoadState.LOADED && order.orderType === 2)) &&
                                <Label className="col-md-6 mt-4">
                                    <ControlledCheckboxInput<IOrderModel>
                                        rules={{ required: true }}
                                        control={control}
                                        readonly={formIsReadOnly}
                                        fieldName="smsConsentCollected"                                        
                                    />{t('FIELDLABEL_SMS_CONSENT')}*
                                    <div className="field-description">{t('FIELD_DESCRIPTION_SMS_CONSENT_GROUP')}</div>
                                </Label>
                            }
                        </Row>
                        </>
                    }
                        <hr className="mt-4" />
                    <Row>
                        <Label className="col-md-12 mt-4">{t('FIELDLABEL_REQUESTED_SERVICE_DESCRIPTION')}*
                            <ControlledTextArea<IOrderModel>
                                rules={{ required: true }}
                                control={control}
                                fieldName="requestedServiceDescription"
                                readonly={formIsReadOnly}
                                placeholder={t('FIELD_PLACEHOLDER_REQUESTED_SERVICE_DESCRIPTION')}
                            />
                        </Label>
                    </Row>
                    <Row>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_OTHER_INFORMATION')}
                            <ControlledTextArea<IOrderModel>
                                control={control}
                                readonly={formIsReadOnly}
                                fieldName="otherInformation"
                            />
                            
                        </Label>
                        <div className="col-md-6 mt-4">
                                
                                {formIsReadOnly && readOnlyOrder && readOnlyOrder.attachments && !!readOnlyOrder.attachments.length &&
                                        <>
                                            <Label>{t('FIELDLABEL_SELECTED_ATTACHMENTS')}</Label>
                                            <ul>
                                                {readOnlyOrder.attachments.map((a: IFileDownloadInfo, index: number) => <li key={index}><button onClick={(event:any) => { event.preventDefault(); event.stopPropagation(); downloadAttachment(a.url, a.displayName); }} >{a.displayName} <i className="fa fa-file-download remove-attachment-btn"></i></button></li>)}
                                            </ul>
                                        </>
                                }
                                {!formIsReadOnly &&
                                    <>
                                        <Label>{t('FIELDLABEL_SELECTED_ATTACHMENTS')}
                                            {order.attachments && !!order.attachments.length &&
                                            <ul className="attachments">
                                                    {order.attachments.map((a: File, index: number) => <li key={index}>{a.name} <i onClick={(event) => { event.preventDefault(); event.stopPropagation(); removeAttachment(index) }} title={t('TITLE_REMOVE_ATTACHMENT')} className="fa fa-minus-circle remove-attachment-btn"></i></li>)}
                                                </ul>
                                            }
                                            <ControlledFileUploadInput<IOrderModel>
                                                className="hidden"
                                                control={control}
                                                fieldName="attachments"
                                                readonly={formIsReadOnly}
                                                placeholder={t('FIELD_PLACEHOLDER_ATTACHMENTS')}
                                            />
                                            <div className="btn-primary attachment-btn  mt-2">{t('FIELD_PLACEHOLDER_ATTACHMENTS')}</div>
                                            
                                        </Label>
                                    </>
                                }
                        </div>
                            <div className="col-md-12 field-description">{t('FIELD_DESCRIPTION_OTHER_INFORMATION')}</div>

                    </Row>
                    
                    <Row>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_REQUESTED_DELIVERY_DATE')}*
                            {(!formIsReadOnly || order.requestedDeliveryDateSpecification == "asap") &&
                            <ControlledSelect<IOrderModel, string>
                                rules={{ required: true }}
                                control={control}
                                fieldName="requestedDeliveryDateSpecification"
                                placeholder={t('FIELD_PLACEHOLDER_SPECIFIC_FEEDBACK_RECIPIENT')}
                                getOptionLabel={(item: IOption<string>) => item.label}
                                getOptionValue={(item: IOption<string>) => item.value.toString()}
                                readonly={formIsReadOnly}
                                
                                options={[
                                    { label: t('OPTION_REQUESTED_DELIVERYDATE_ASAP'), value: "asap" },
                                    { label: t('OPTION_REQUESTED_DELIVERYDATE_SPECIFIC_DATE') + "...", value: "specificDate" }]}
                            />
                            }
                            {order.requestedDeliveryDateSpecification === "specificDate" && 
                                <ControlledDatePickerInput<IOrderModel>
                                    rules={{ required: true }}
                                    control={control}
                                    fieldName="requestedDeliveryDate"
                                    readonly={formIsReadOnly}

                                    placeholder={t('FIELD_PLACEHOLDER_REQUESTED_DELIVERY_DATE')}
                                />
                            }
                        </Label>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_DIGITAL_DELIVERY')}*
                            <ControlledRadioButtons<IOrderModel,string>
                                rules={{ required: true }}
                                transform={{
                                    toFormValue : i => i.value,
                                    toSelectedValue : i => ({ label: i.toString(), value:i}),
                                    selectedValueToString : i => i.value.toString(),
                                    formValueToString : o => o.toString()
                                }}
                                control={control}
                                readonly={formIsReadOnly}
                                fieldName="digitalDeliveryIsPreferred"
                                options={[{ label: t('OPTION_DIGITAL_DELIVERY_YES'), value: "yes" }, { label: t('OPTION_DIGITAL_DELIVERY_NO'), value: "no" }]}
                            />
                        </Label>
                    </Row>
                    <Row>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_FEEDBACK_METHOD')}*
                            <ControlledSelect<IOrderModel, number>
                                rules={{ required: true }}
                                placeholder={t('OPTION_FEEDBACK_METHOD_PLACEHOLDER')}
                                control={control}
                                fieldName="feedbackMethod"
                                getOptionLabel={(item: IOption<number>) => item.label}
                                getOptionValue={(item: IOption<number>) => item.value.toString()}
                                readonly={formIsReadOnly}
                                options={feedbackOptions}/>
                                <span className="field-description">{t('OPTION_FEEDBACK_METHOD_YES_DESCRIPTION')}</span>
                        </Label>

                        {order.feedbackMethod > 0 &&
                            <Label className="col-md-6 mt-4">{t('FIELDLABEL_SPECIFIC_FEEDBACK_RECIPIENT')}*
                            <ControlledSelect<IOrderModel, string>
                                    rules={{ required: true }}
                                    control={control}
                                    fieldName="specificFeedbackRecipient"
                                    placeholder={t('FIELD_PLACEHOLDER_SPECIFIC_FEEDBACK_RECIPIENT')}
                                    getOptionLabel={(item: IOption<string>) => item.label}
                                    getOptionValue={(item: IOption<string>) => item.value.toString()}
                                    readonly={formIsReadOnly}
                                    
                                    options={[
                                        { label: t('OPTION_SPECIFIC_FEEDBACK_RECIPIENT_YES'), value: "yes" },
                                        { label: t('OPTION_SPECIFIC_FEEDBACK_RECIPIENT_NO'), value: "no" }]}
                                />

                                {order.specificFeedbackRecipient === "yes" && <>
                                    {t('FIELD_PLACEHOLDER_SPECIFIC_FEEDBACK_RECIPIENT_DETAILS')}*
                                <ControlledTextArea<IOrderModel>
                                        rules={{ required: true }}
                                        control={control}
                                        fieldName="specificFeedbackRecipientDetails"
                                        readonly={formIsReadOnly}
                                        placeholder={t('FIELD_PLACEHOLDER_SPECIFIC_FEEDBACK_RECIPIENT_DETAILS')}
                                    />
                                </>
                                }
                            </Label>
                        }
                    </Row>
                    {order.orderStatus === 2 &&
                        <Row>
                            <Label className="col-md-6 mt-4">{t('BOOKING_DETAILS')}
                                <div className="fake form-control" dangerouslySetInnerHTML={{ __html: sanitizeHtml(order.bookingDetails) }} />
                            </Label>
                            <Label className="col-md-6 mt-4">{t('SCHEDULED_DATE')}
                                <Input className="form-control " readOnly={true} value={dayjs(order.scheduledDate).format("YYYY-MM-DD HH:mm")}
                                />
                            </Label>
                        </Row>
                    }
                    <Row>
                        <Label className="col-md-6 mt-4">{t('FIELDLABEL_PREFERRED_PERFORMING_UNIT')}*
                            <ControlledSelect<IOrderModel, string>
                                rules={{ required: true }}
                                control={control}
                                fieldName="preferredPerformingUnit"
                                options={aEHierarchyOptions}
                                getOptionLabel={(item: IOption<string>) => item.label}
                                getOptionValue={(item: IOption<string>) => item.value}
                                readonly={formIsReadOnly}
                                placeholder={t('FIELD_PLACEHOLDER_PREFERRED_PERFORMING_UNIT')}
                            />
                        </Label>

                        { (isEducationOrder || isGroupOrder) &&
                            <Label className="col-md-6 mt-4">{t('FIELDLABEL_ADDITIONAL_PERFORMING_UNITS')}
                            <ControlledSelect<IOrderModel, string>
                                    isMulti={true}
                                    control={control}
                                    fieldName="additionalPerformingUnits"
                                    options={aEHierarchyOptions}
                                    getOptionLabel={(item: IOption<string>) => item.label}
                                    getOptionValue={(item: IOption<string>) => item.value}
                                    readonly={formIsReadOnly}
                                    placeholder={t('FIELD_PLACEHOLDER_PREFERRED_PERFORMING_UNIT')}
                                />
                            </Label>
                        }
                    </Row>
                    
                    {!formIsReadOnly &&
                    <>

                        <Row> 
                                <Col className="col-12 mt-4">
                                <Input className="btn-primary" type="submit" placeholder={t('ORDER_SUBMIT')} value={submitButtonText} disabled={submissionState !== SubmissionState.IDLE} />
                                
                            </Col>
                                <Col className="col-12 mt-4">
                                <span className="field-description">{t('ORDER_RULES_AND_CONDITIONS')}</span>
                            </Col>
                        </Row>
                    </>
                    }
                    </fieldset>
                </Form>

               
            </>}
            {submissionState === SubmissionState.SUBMITTED &&
                <Modal isOpen={showResultModal} onClosed={() => { setShowResultModal(false) }}>
                    <ModalHeader>
                    {t('ORDER_SUBMISSION_SUCCESS_HEADING')}
                    </ModalHeader>
                    <ModalBody>
                    {t('ORDER_SUBMISSION_SUCCESS_CONTENT')}
                    </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => { 
                            setShowResultModal(false) 
                            navigate("/service/order");
                        }}
                    >
                        OK
                    </Button>
                </ModalFooter>
                </Modal>
            }
            {submissionState === SubmissionState.SUBMITTING &&
                <Modal isOpen={showResultModal}>
                    <ModalBody>
                    {t('ORDER_SUBMISSION_PENDING')}
                    </ModalBody>
                </Modal>
            }
            {submissionState === SubmissionState.ERROR &&
                <Modal isOpen={showResultModal} onClosed={() => { setShowResultModal(false)}}>
                    <ModalHeader>
                    {t('ORDER_SUBMISSION_ERROR_HEADING')}
                    </ModalHeader>
                    <ModalBody>
                    {t('ORDER_SUBMISSION_ERROR_CONTENT')}
                    </ModalBody>
                <ModalFooter>
                    <Button
                        className="btn-primary"
                        onClick={() => { setShowResultModal(false) }}
                    >
                        OK
                    </Button>
                </ModalFooter>
                </Modal>
            }
        </div>
    );
}

export default connect((appState: ApplicationState) => ({ authenticationState: appState.authentication }),
    { ...AuthenticationStore.actions })(Order);