import * as React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import DatePicker from 'react-datepicker'
import './FormComponents.css';
import { Control, Controller, FieldPath, FieldValues, RegisterOptions } from "react-hook-form";
import Select, { MultiValue } from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';

export interface ICustomTextProps<T extends FieldValues> {
    control: Control<T, object>;
    fieldName: FieldPath<T>;
    placeholder?: string;
    readonly?: boolean;
    className?:string;
    rules?: Omit<RegisterOptions<T, FieldPath<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}

export interface ITransform<TSelectedValue,TFormValue>{
    toFormValue: (i: TSelectedValue) => TFormValue,
    toSelectedValue: (i: TFormValue) => TSelectedValue,
    selectedValueToString:(i: TSelectedValue) => string,
    formValueToString:(o: TFormValue) => string
}
export interface ICustomRadioButtonsProps<T extends FieldValues, TOptionValue> {
    control: Control<T, object>;
    fieldName: FieldPath<T>;
    options: IOption<TOptionValue>[];
    transform?: ITransform<IOption<TOptionValue>, TOptionValue>,
    rules?: Omit<RegisterOptions<T, FieldPath<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    readonly?: boolean;
}
export interface ICustomSelectProps<T extends FieldValues, TOption> {
    control: Control<T, object>;
    fieldName: FieldPath<T>;
    options: Array<IOption<TOption>>;
    placeholder?: string;
    getOptionLabel: (item: IOption<TOption>) => string;
    getOptionValue: (item: IOption<TOption>) => string;
    isMulti?:boolean;
    readonly?: boolean;
    rules?: Omit<RegisterOptions<T, FieldPath<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}
export interface ICustomFileUploadProps<T extends FieldValues> {
    control: Control<T, object>;
    fieldName: FieldPath<T>;
    placeholder?: string;
    rules?: Omit<RegisterOptions<T, FieldPath<T>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    className?:string;
    readonly?: boolean;
}
export interface IOption<T> {
    value: T;
    label: string;
    recursionLevel?: number;
    isDeprecated?: boolean
}

export function ControlledRadioButtons<T extends FieldValues, TOptionValue>({ fieldName, control, options, rules, transform, readonly }: ICustomRadioButtonsProps<T, TOptionValue>) {
    
    const getOptionFromValue = (value: TOptionValue) => {
        var candidates = options.filter(o => o.value === value);
        if (candidates.length === 1) {
            return candidates[0];
        }
        return undefined;
    }

    return <Controller
        name={fieldName}
        control={control}        
        rules={rules || {}}
        render={
            ({ field, fieldState,formState }) => {
                
                const isInvalid: boolean = fieldState.invalid && (fieldState.isTouched || formState.isSubmitted);
                const className = isInvalid ? "radiobutton-group is-invalid" :"";
                
                const onChange = (e:any) => {
                    if(transform !== undefined){
                        const valueAsString = e.currentTarget.value;
                        const valueAsObject = options.filter((o) => transform.selectedValueToString(o) === valueAsString);
                        field.onChange(valueAsObject.length ? valueAsObject[0].value : options[0].value);
                    }else{
                        field.onChange(e);
                    }
                    
                };

                if (readonly) {
                    const o: IOption<TOptionValue> | undefined = getOptionFromValue(field.value as TOptionValue);
                    return <Input readOnly value={o ? o.label : field.value as string} />
                }

                return (<FormGroup check className={className}>{options.map((o, index) =>
                    <Label key={index} check>
                        <Input type="radio"
                            readOnly={readonly}
                            name={field.name} 
                            onChange={onChange} 
                            value={transform ? transform.selectedValueToString(o) : (o.value as any).toString() } 
                            checked={o.value === field.value} /> 
                                {o.label}<br />
                    </Label>)}</FormGroup>);
            }
        } />
}

export function ControlledCheckboxInput<T extends FieldValues>({ fieldName, control, placeholder, rules, readonly }: ICustomTextProps<T>) {
    return <Controller
        name={fieldName}
        control={control}
        rules={rules || {}}
        
        render={
            ({ field, fieldState, formState }) => {

                const isValid: boolean = (!fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) && !readonly;
                const isInvalid: boolean = (fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) && !readonly;
                return (<Input defaultChecked={field.value} type={'checkbox'} ref={field.ref} value={field.value as string} disabled={readonly} valid={isValid} invalid={isInvalid} name={field.name} onBlur={field.onBlur} onChange={field.onChange} />)
            }
        } />
}

export function ControlledSelect<T extends FieldValues, TOption>({ fieldName, control, options, rules, placeholder, getOptionLabel, getOptionValue, isMulti, readonly }: ICustomSelectProps<T, TOption>) {

    const getOptionFromValue = (value:TOption, includeDeprecated?:boolean) => {
        var candidates = options.filter(o =>  o.value === value && (includeDeprecated || ! o.isDeprecated) );
        if(candidates.length === 1){
            return candidates[0];
        }
        return undefined;
    }

    const viableOptions = options.filter(o => !o.isDeprecated);

    return <Controller
        name={fieldName}
        control={control}
        rules={rules || {}}
        render={({ field, fieldState, formState }) => {

            var className: string = "";

            if (!fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) {
                className = "dropdown is-valid";
            }
            else if (fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) {
                className = "dropdown is-invalid";
            }

            if(readonly){
                className += " is-read-only"
            }


            if(readonly){
                if(isMulti){ 
                    const f = (field.value as TOption[])
                        .map(i => {
                            var value = getOptionFromValue(i)?.label;
                            return value || i
                        }).join(", ");
                    return <Input readOnly value={f} type={"textarea"} />
                }else{
                    const o: IOption<TOption> | undefined = getOptionFromValue(field.value as TOption);
                    return <Input  readOnly value={o ? o.label : JSON.stringify(field.value)} />
                }
            }
            
            let component = isMulti 
                ? (<Select<IOption<TOption>, boolean>
                    isMulti={isMulti}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    placeholder={placeholder}
                    classNamePrefix="drop-"
                    name={field.name}
                    ref={field.ref}
                    value={getOptionFromValue(field.value as TOption)}
                    onBlur={field.onBlur}
                    onChange={(e) => { field.onChange(!!e ? (e as MultiValue<IOption<TOption>>).map((p:any) => p.value) : undefined) } }
                    className={className}
                    options={viableOptions}
                />)
                : (<Select<IOption<TOption>>                 
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    placeholder={placeholder}
                    classNamePrefix="drop-"
                    name={field.name}
                    ref={field.ref}
                    value={getOptionFromValue(field.value as TOption)}
                    onBlur={field.onBlur}
                    onChange={(e) => { 
                        console.log(e);
                        field.onChange(e ? e.value : ""); }}
                    className={className}
                    options={viableOptions}
                />)
                return component;
        }} />
}

export function ControlledTextInput<T extends FieldValues>({ fieldName, control, placeholder, rules, readonly }: ICustomTextProps<T>) {
    return <Controller
        name={fieldName}
        control={control}
        rules={rules || {}}
        
        render={
            ({ field, fieldState, formState }) => {

                const isValid: boolean = (!fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) && !readonly;
                const isInvalid: boolean = (fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) && !readonly;
                return (<Input ref={field.ref} value={field.value as string}  readOnly={readonly} valid={isValid} invalid={isInvalid} name={field.name} onBlur={field.onBlur} onChange={field.onChange} placeholder={placeholder || ""} />)
            }
        } />
}

export function ControlledNumberInput<T extends FieldValues>({ fieldName, control, placeholder, rules, readonly }: ICustomTextProps<T>) {
    return <Controller
        name={fieldName}
        control={control}
        rules={rules || {}}

        render={
            ({ field, fieldState, formState }) => {

                const isValid: boolean = (!fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) && !readonly;
                const isInvalid: boolean = (fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) && !readonly;
                return (<Input type='number' ref={field.ref} value={field.value as string} readOnly={readonly} valid={isValid} invalid={isInvalid} name={field.name} onBlur={field.onBlur} onChange={field.onChange} placeholder={placeholder || ""} />)
            }
        } />
}

export function ControlledFileUploadInput<T extends FieldValues>({ fieldName, control, placeholder, rules, className }: ICustomFileUploadProps<T>) {
    return <Controller
        name={fieldName}
        control={control}
        rules={rules || {}}
        render={
            ({ field, fieldState, formState }) => {
                const isValid: boolean = !fieldState.invalid && (fieldState.isTouched || formState.isSubmitted);
                const isInvalid: boolean = fieldState.invalid && (fieldState.isTouched || formState.isSubmitted);
                var items = field.value as File[] || [];
                
                return (<Input className={className ||""} control={control} type="file" multiple={true} valid={isValid} invalid={isInvalid} onChange={(e) => {  
                    field.onChange([...items, ...Array.from(e.currentTarget.files || [])]) 
                }
                } placeholder={placeholder || ""} accept=".pdf, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .csv" />)
            }
        } />
}

export function ControlledDatePickerInput<T extends FieldValues>({ fieldName, control, placeholder, rules, readonly }: ICustomTextProps<T>) {

    return <Controller
        name={fieldName}
        control={control}
        rules={rules || {}}
        render={
            ({ field, fieldState, formState }) => {
                
                if (readonly && !field.value) {
                    return <Input readOnly value=""/>
                }
                
                var className: string = "form-control";
                if (!fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) {
                    className = "datepicker is-valid form-control";
                }
                else if (fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) {
                    className = "datepicker is-invalid form-control";
                }
                return (<DatePicker minDate={new Date(Date.parse(Date().toString()) + (3600 * 7000 * 24))} readOnly={readonly} showWeekNumbers={true} shouldCloseOnSelect={true} id={fieldName} className={className} value={field.value ? dayjs(new Date(field.value as string)).format("YYYY-MM-DD") : ""} selected={field.value ? new Date(field.value as string) : null}  onChange={(date, event) => { event?.preventDefault(); field.onChange(date !== null ? date : "") }} placeholderText={placeholder || ""} />)
            }
        } />
}

export function ControlledTextArea<T extends FieldValues>({ fieldName, control, placeholder, rules, readonly, className }: ICustomTextProps<T>) {
    return <Controller
        name={fieldName}
        control={control}
        rules={rules || {}}
        render={
            ({ field, fieldState, formState }) => {
                const isValid: boolean = (!fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) && !readonly;
                const isInvalid: boolean = (fieldState.invalid && (fieldState.isTouched || formState.isSubmitted)) && !readonly;
                return (<Input className={className} readOnly={readonly} type="textarea" valid={isValid} invalid={isInvalid} {...field} value={field.value as string} placeholder={placeholder || ""} />)
            }
        }
    />
}